@import url('bootstrap-icons/font/bootstrap-icons.css');
@import url('./libraries/ol/ol.css');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
	font-family: 'adineue PRO Cyr Light';
	src: url('libraries/adi/adineuePROCyr-LightWeb.eot');
	src: url('libraries/adi/adineuePROCyr-LightWeb.eot?#iefix') format('embedded-opentype'),
	url('libraries/adi/adineuePROCyr-LightWeb.woff') format('woff'),
	url('libraries/adi/adineuePROCyr-LightWeb.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'adineue PRO Cyr Bold';
	src: url('libraries/adi/adineuePROCyr-BoldWeb.eot');
	src: url('libraries/adi/adineuePROCyr-BoldWeb.eot?#iefix') format('embedded-opentype'),
	url('libraries/adi/adineuePROCyr-BoldWeb.woff') format('woff'),
	url('libraries/adi/adineuePROCyr-BoldWeb.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Reey';
	src: url('libraries/Reey/Reey-Regular.otf');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Breymont';
	src: url('libraries/Breymont_v2/Breymont-Bold.ttf');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Breymont';
	src: url('libraries/Breymont_v2/Breymont-Light.ttf');
	font-weight: 300;
	font-style: normal;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body::-webkit-scrollbar {
	display: none;
} 
body {
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
}
* {
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
}
*::-webkit-scrollbar {
	display: none;
}
@media (min-width: 1600px) {
	body .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 1550px;
	}
}
@media (min-width: 1800px) {
	body .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1750px;
    }
}
@media (min-width: 2000px) {
	body .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 1950px;
	}
}
@media (min-width: 2200px) {
	body .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 2150px;
	}
}
@media (min-width: 2400px) {
	body .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 2350px;
	}
}
@media (min-width: 2600px) {
	body .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 2550px;
	}
}
@media (min-width: 2800px) {
	body .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 2750px;
	}
}
